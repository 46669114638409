<template>
  <div>
    <b-alert
      variant="danger"
      :show="post === undefined"
    >
      <h4 class="alert-heading">
        Error fetching post data
      </h4>
      <div class="alert-body">
        No post found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'post-list' }"
        >
          Post List
        </b-link>
        for other posts.
      </div>
    </b-alert>

    <b-alert
      variant="warning"
      :show="((post && post.id !== null) && post.postStatusId === POST_STATUS_EDITED_ID)"
    >
      <h4 class="alert-heading">
        This post has been edited
      </h4>
      <div
        class="alert-body"
        v-if="post && post.postId !== null"
      >
        This is just a preview of the original post. Check the
        <b-link
          class="alert-link"
          :to="{ name: 'post-detail', params: { id: post.postId }, }"
        >
          Post #{{ post.postId }}
        </b-link>
        to see the new changes.
      </div>
    </b-alert>

    <template>
      <b-row
        v-if="post"
        class="invoice-preview"
      >
        <b-col :lg="(post.postStatusId !== POST_STATUS_EDITED_ID) ? 8 : 12">
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body class="pb-0">
              <div class="d-flex flex-md-row flex-column justify-content-between align-items-md-center mb-2">
                <b-col
                  md="6"
                  class="text-right text-md-left"
                >
                  <div class="d-flex d-row">
                    <b-badge
                      id="post-status"
                      :variant="`${getPostStatusVariant(post.postStatusId)}`"
                      class="mb-1"
                    >
                      {{ post.postStatus.name }}
                    </b-badge>
                    <b-tooltip
                      target="post-status"
                      placement="top"
                    >
                      <p class="mb-0">
                        Post Status
                      </p>
                    </b-tooltip>
                  </div>
                  <h4>
                    Post
                    <span class="font-weight-bolder">#{{ post.id }}</span>
                  </h4>
                </b-col>

                <b-col
                  md="6"
                  class="post-wrap mt-md-0 mt-1"
                >
                  <div class="d-flex align-items-center justify-content-md-between mb-75">
                    <h6 class="post-label mb-25">Date Added:</h6>
                    <p class="pl-25 mb-25">{{ dateFormated(post.dateAddedUtc) }}</p>
                  </div>
                </b-col>
              </div>

              <div class="d-flex flex-md-row flex-column justify-content-sm-end align-items-md-center">
                <b-col
                  md="6"
                  class="text-right text-md-left"
                >
                  <div class="d-flex align-items-center justify-content-md-between mb-75">
                    <h6 class="post-label mb-25">Account:</h6>
                    <p class="pl-25 mb-25">{{ post.account.name }}</p>
                  </div>
                </b-col>
              </div>
              <div class="d-flex flex-md-row flex-column justify-content-sm-end align-items-md-center">
                <b-col
                  md="6"
                  class="text-right text-md-left"
                >
                  <div class="d-flex align-items-center justify-content-md-between mb-75">
                    <h6 class="post-label mb-25">Company:</h6>
                    <p class="pl-25 mb-25">{{ post.company.name }}</p>
                  </div>
                </b-col>
              </div>

            </b-card-body>

            <b-table-lite
              responsive
              :items="post.postTaxis"
              :fields="['driver', 'rate']"
            >
              <template #cell(driver)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ `${data.item.taxi.firstName} ${data.item.taxi.lastName}` }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taxi.licenceNum }}
                </b-card-text>
              </template>
              <template #cell(rate)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ formatCurrency(data.item.taxiRate) }}
                </b-card-text>
              </template>
            </b-table-lite>

            <b-card-body class="invoice-padding">
              <b-row>
                <b-col md="6">
                  <b-card-text>
                    <h6>Notes</h6>
                    <p>{{ post.notes }}</p>
                  </b-card-text>
                </b-col>

                <b-col
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                >
                  <div class="invoice-total-wrapper">

                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Taxi Rate:</p>
                      <p class="invoice-total-amount">£{{ taxiRateAmount }}</p>
                    </div>

                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Account:</p>
                      <p class="invoice-total-amount">{{ formatCurrency(post.accountCommission) }}</p>
                    </div>

                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title font-weight-bolder">Total</p>
                      <p class="invoice-total-amount">{{ formatCurrency(post.postTotalFee) }}</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col :lg="(post.postStatusId !== POST_STATUS_EDITED_ID) ? 4 : 12">
          <b-col
            v-if="post.postStatusId !== POST_STATUS_EDITED_ID"
            class="px-0 py-0"
          >
            <b-card>
              <b-button
                variant="outline-primary"
                class="mb-75"
                block
                :to="{ name: 'post-edit', params: { id: $route.params.id } }"
              >
                Edit
              </b-button>

              <b-button
                variant="outline-primary"
                class="mb-75"
                block
                @click="changeStatus"
              >
                {{ (post.postStatusId === 1) ? 'Mark as Pending' : 'Mark as Paid' }}
              </b-button>

              <b-button
                variant="outline-primary"
                class="mb-75"
                block
                @click="printPost"
              >
                Print
              </b-button>

              <b-button
                variant="outline-secondary"
                class="mb-75"
                block
                @click="openPostAttachmentFormModal"
              >
                Attach files
              </b-button>
            </b-card>
          </b-col>

          <b-col class="px-0 py-0">
            <post-attach-list />
          </b-col>
        </b-col>
      </b-row>
      <post-attach-modal ref="postAttachmentFormRef" />
    </template>
  </div>
</template>

<script>
import { BAlert, BBadge, BButton, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTableLite, VBToggle, BTooltip } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import PostAttachList from '@/modules/post/components/PostAttachList.vue';
import PostAttachModal from '@/modules/post/components/PostAttachModal.vue';
import router from '@/router';
import Swal from 'sweetalert2';
import usePost from '@/modules/post/composables/usePost';
import usePostAttachment from '@/modules/post/composables/usePostAttachment';
import usePostUtils from '@/modules/post/composables/usePostUtils';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { getPost, changePostStatus, downloadPost } = usePost();
    const { getPostAttachments } = usePostAttachment();
    const { formatDateDdMmYyyyHi, convertUtcToLocalDate, formatCurrency } = useUtils();
    const { POST_STATUS_EDITED_ID, getPostStatusVariant } = usePostUtils();

    const post = ref(null);
    const taxiRateAmount = ref(0);
    const postAttachmentFormRef = ref(null);


    const dateFormated = (date) => formatDateDdMmYyyyHi(convertUtcToLocalDate(date));
    const openPostAttachmentFormModal = () => { postAttachmentFormRef.value.$refs.refModal.show(); };

    const getTaxiRateAmount = (taxiArray) => {
      const result = taxiArray.reduce((total, taxi) => total + taxi.taxiRate, 0);
      return result.toFixed(2);
    };

    const initData = async () => {
      await getPostAttachments(router.currentRoute.params.id);
      const data = await getPost(router.currentRoute.params.id);
      post.value = data;
      taxiRateAmount.value = getTaxiRateAmount(data.postTaxis);
    }

    const changeStatus = async () => {
      const { value } = await Swal.fire({
        title: 'Change Post Status',
        text: 'This action will change the status of all linked transactions',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, continue!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await changePostStatus(post.value.id);
      const data = await getPost(router.currentRoute.params.id);
      post.value = data;
    }

    const printPost = async () => {
      const response = await downloadPost(post.value.id)

      if (response !== null) {
        // create file link in browser's memory
        const url = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    }

    initData();

    return {
      // Const 
      POST_STATUS_EDITED_ID,

      // Reactive
      post,
      postAttachmentFormRef,
      taxiRateAmount,

      //Method
      changeStatus,
      dateFormated,
      formatCurrency,
      getPostStatusVariant,
      openPostAttachmentFormModal,
      printPost
    };
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BCol,
    BLink,
    BRow,
    BTableLite,
    BTooltip,
    PostAttachList,
    PostAttachModal,
    VBToggle,
  }
};
</script>

<style lang="scss" scoped>
.post-wrap {
  max-width: 300px;
}

.post-label {
  max-width: 8rem;
}

@import "~@core/scss/base/pages/app-invoice.scss";
</style>