import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const usePostAttachment = () => {
  const toast = useToast();

  const getPostAttachments = async (id) => {
    try {
      return await store.dispatch('postAttachment/getPostAttachmentList', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Post not found',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const uploadPostAttachments = async (postId, postAttachments) => {
    try {
      return await store.dispatch('postAttachment/uploadFiles', {
        postId,
        postAttachments,
      });
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const deletePostAttachment = async (id) => {
    try {
      await store.dispatch('postAttachment/deleteFile', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const downloadPostAttachment = async (id) => {
    try {
      return await store.dispatch('postAttachment/downloadFile', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    deletePostAttachment,
    downloadPostAttachment,
    getPostAttachments,
    uploadPostAttachments,
  };
};

export default usePostAttachment;
